import { nanoid } from 'nanoid';

// HEAD DATA
export const headData = {
title: 'Orlando Coyoy', // e.g: 'Name | Developer'
lang: 'en', // e.g: en, es, fr, jp
description: 'Orlando Coyoy is a full stack Web Developer with experience in web application development and CMS solutions.', // e.g: Welcome to my website
};


// ABOUT DATA
export const aboutData = {
img: 'profile.jpg',
paragraphOne: 'I am a Web Developer with Frontend and Backend skills.',
paragraphTwo:
'I am passionate about providing a positive user experience and learning different development technologies.',
paragraphThree: 'I also strive to create efficient workflows and positive work environments.',
resume: 'https://drive.google.com/file/d/1nUwoZWZeqsEMU0t2jB0bvIKddjQXE2tu/view?usp=sharing',
};

// PROJECTS DATA
export const projectsData = [
{
id: nanoid(),
img: 'oasisThumbnail.png',
title: 'Oasis Pharmaceutical Management Inventory System',
info:
'A web app that keeps track of a clinic\'s pharmaceuticals. Each pharmaceutical in the inventory is able to be searched by Generic Name, Brand Name, or Common Uses.',
info2: 'This project was made with ReactJS and SpringBoot.',
button:'Case Study',
url: '/oasisCaseStudy',
tech: 'ReactJS, SpringBoot, MySQL'
},
{
id: nanoid(),
img: 'techandlit.png',
title: 'Technological Literacy Platform',
info:
'A platform with quick and digestible media for anyone who does not have the time, resources and/or privilege to discuss the nuances of information and communications technologies. The platform seeks to provide educational value, engagement, thought experiments, and new perspectives on digital technology for the user that engages with this platform.',
info2: 'This project was made with GatsbyJS, ReactJS, and Ghost CMS',
url: 'https://techandliteracy.com/',
tech: 'GatsbyJS, ReactJS, Ghost CMS, GraphQL'
},
];

// CONTACT DATA
export const contactData = {
cta: 'Would you like to work with me? Awesome!',
btn: "Let's build together!",
email: 'orlandocoyoy17@gmail.com',
};

// FOOTER DATA
export const footerData = {
networks: [
{
id: nanoid(),
name: 'github',
url: 'https://github.com/ocoyoyix',

},
{
id: nanoid(),
name: 'linkedin',
url: 'https://linkedin.com/in/orlando-coyoy-ixquiacche-0a0b29181/', // your linkedin url
},
],
};

// Github start/fork buttons
export const githubButtons = {
isEnabled: false, // set to false to disable the GitHub stars/fork buttons
};

export const heroData = {
title: 'Hello, My name is', // Hello, my name is
name: 'Orlando Coyoy', // John
subtitle: 'I am a Full Stack Web Developer', // I'm the Unknown Developer.
cta: 'Learn More', // Know more
};
